<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/routineTask' }">外勤计划</el-breadcrumb-item>
          <el-breadcrumb-item>任务详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="任务名称：" prop="taskName">{{ form.taskName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务描述：" prop="taskDesc">{{ form.taskDescribe }}</el-form-item>
            </el-col>
          </el-row>
          
          <!-- 外勤人员配置 -->
          <div class="config-title">
            <div class="form-title">外勤人员配置</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="外勤人员：">
                {{ form.staffName }}
                <!-- <div class="staffBox">
                  <div v-if="staffInfos.length>0">
                    <el-tag v-for="staff in staffInfos" :key="staff.reconditionStaffId" style="margin-right: 4px;">
                      {{ staff.staffName }}
                    </el-tag>
                  </div>
                  <div v-else style="color: #909399;text-align: center;">无外勤人员</div>
                </div> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="起止日期：" prop="date">
            {{ dayjs(form.startDate).format('YYYY-MM-DD') }} - {{ dayjs(form.endDate).format('YYYY-MM-DD') }}
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="应出勤天数：" prop="days">{{ form.attendanceDay }} 天</el-form-item>
            </el-col>  
          </el-row>

          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/routineTask')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import layTime from '@/components/layTime'
export default {
  components: {
    layTime
  },
  data() {
    return {
      loading: false,
      row: {},
      form: {},
      rules: {},
      staffInfos: [],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    }
  },
  methods: {
    dayjs,
    dateSecond(str) {
      let strArr = str.split(':')
      let second =  (strArr[0] * 3600) + (strArr[1] * 60) + Number(strArr[2])
      return second
    },

    loadDetail() {
      this.loading = true
      this.$ajax.post('routineDetail',{}, {
        routineTaskCode: this.row.routineTaskCode
      }).then(res => {
        this.form = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/routineTask");
      return;
    }
    this.row = this.$route.params.row;
    this.loadDetail()
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.config-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.staffBox {
  width: 325px;
  background: #fff;
  margin-right: 20px;
  padding: 4px;
  min-height: 40px;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
//   box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
}

.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>